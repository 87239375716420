<template>
    <div class="Inquiry">
        <div class="Inquiry_Box">
            <div class="Inquiry_Box_Top">
                <div class="Inquiry_Box_Top_Left">
                    <div class="Inquiry_Box_Top_Left_Title">
                        <p>订单确认信息</p>
                    </div>
                    <div class="Inquiry_Content">
                        <!-- 城市 -->
                        <div class="Inquiry_Content_City">
                            <!-- 提箱城市 -->
                            <div class="suitcaseCity">
                                <div><span>提箱城市</span></div>
                                <div><span>{{ orderMessage.departure }}</span></div>
                            </div>
                            <!-- 还箱城市 -->
                            <div class="returnBox">
                                <div><span>还箱城市</span></div>
                                <div><span>{{ orderMessage.destination }}</span></div>
                            </div>
                            <!-- 集装箱类型 -->
                            <div class="containerType">
                                <div><span>集装箱类型</span></div>
                                <div><span>{{ orderMessage.cupboard }}</span></div>
                            </div>
                        </div>
                        <div class="Inquiry_Bc">
                            <div class="choice_Box">
                                <!-- 选择提箱堆场 -->
                                <div class="choice_Box_Left">
                                    <p>选择提箱堆场</p>
                                </div>
                                <div class="choice_Box_Right">
                                    <el-select v-model="choiceValue" placeholder="不同的提箱堆场，库存和价格可能会有差异"
                                        @change="changeOrder(choiceValue)">
                                        <el-option v-for="(item, index) in orderMessage.expList" :key="index"
                                            :label="item.suitcase" :value="index">
                                        </el-option>
                                    </el-select>
                                    <div class="placeholder" v-show="value">
                                        <p>不同的提箱堆场，库存和价格可能会有差异</p>
                                    </div>
                                </div>
                            </div>
                            <!-- 提箱地址 -->
                            <div class="suitcaseAddress">
                                <el-form>
                                    <el-form-item label="提箱地址" prop="region">
                                        <el-select v-model="addressValue" placeholder="">
                                            <el-option :accesskey="addressitem.address" :value="addressitem.address">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <!-- 租箱个数 -->
                            <div class="numberOfRentedBoxes">
                                <div class="numberOfRentedBoxes_Left"><span>租赁个数 (最多可订{{ addressitem.maxBuy
                                        || ''
                                }})</span></div>
                                <div class="numberOfRentedBoxes_Right">
                                    <input type="text" v-model="num" name="" @change="changeTelpone($event)" id=""
                                        placeholder="租赁个数" @input="inputNum1">
                                    <!-- 控制数量 -->
                                    <div class="controlQuantity">
                                        <i class="el-icon-arrow-up" @click="jia"></i>
                                        <i class="el-icon-caret-bottom" @click="jian"></i>
                                    </div>
                                </div>
                            </div>
                            <!-- /电话 -->
                            <div class="telephone">
                                <div class="telephone_Left">
                                    <p>输入您的预留电话</p>
                                </div>
                                <div class="telephone_Right">
                                    <input type="text" v-model="telephoneNum" placeholder="输入您的预留电话" @input="inputNum2">
                                    <!-- <el-input type="number" @change="changeTelpone(Event)" v-model="telephoneNum" min="0"
                                        placeholder="请输入内容"></el-input> -->

                                </div>
                                <div class="placeholder_Two" v-show="telephoneNum">
                                    <p>输入您的预留电话</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- 进度 -->
                    <div class="progressBar">
                        <div>
                            <p>1</p>
                            <span>提交资料</span>
                        </div>
                        <div>
                            <p>2</p>
                            <span>后台库存确认</span>
                        </div>
                        <div>
                            <p>3</p>
                            <span>支付订单</span>
                        </div>
                        <div>
                            <p>1</p>
                            <span>获得提箱单</span>
                        </div>
                    </div>
                </div>
                <div class="Inquiry_Box_Top_Right">
                    <div class="Inquiry_Box_Top_Right_Title">
                        <p>价格详情</p>
                    </div>
                    <!-- 详情 -->
                    <div class="details">
                        <!-- 数量 -->
                        <div class="quantity">
                            <div class="quantity_Title">
                                <p>数量</p>
                                <p>单价</p>
                                <p>总价</p>
                            </div>
                            <div class="quantity_Content">
                                <p>{{ num }}</p>
                                <p>
                                    <span class="prices" v-show="addressitem.currency == 'USD'">$ </span>
                                    <span class="prices" v-show="addressitem.currency == 'EUR'">€</span>
                                    <span class="prices" v-show="addressitem.currency == 'CNY'">￥</span>
                                    {{ addressitem.price }}
                                </p>
                                <p>
                                    <span class="prices" v-show="addressitem.currency == 'USD'">$ </span>
                                    <span class="prices" v-show="addressitem.currency == 'EUR'">€</span>
                                    <span class="prices" v-show="addressitem.currency == 'CNY'">￥</span>
                                    {{ addressitem.price * num || "" }}
                                </p>
                            </div>
                        </div>
                        <!-- 日期/费用 -->
                        <div class="datePrice">
                            <div>
                                <p>租赁天数</p>
                                <p>超期费用</p>
                            </div>
                            <div>
                                <p>{{ addressitem.days || 0 }}日</p>
                                <p>
                                    <span class="prices" v-show="addressitem.currency == 'USD'">$ </span>
                                    <span class="prices" v-show="addressitem.currency == 'EUR'">€</span>
                                    <span class="prices" v-show="addressitem.currency == 'CNY'">￥</span>
                                    {{ addressitem.expPrice || 0 }}/箱/日
                                </p>
                            </div>
                        </div>
                        <!-- 地址 -->
                        <div class="address">
                            <div>
                                <p>下单公司</p>
                                <p>联系电话</p>
                            </div>
                            <div>
                                <p>{{ enterpriseName || '' }}</p>
                                <p>{{ enterpriseTel || '' }}</p>
                            </div>
                        </div>
                        <!-- 总价 -->
                        <div class="totalPrice">
                            <div>
                                <span>总价</span>
                                <span class="prices" v-show="addressitem.currency == 'USD'">$ </span>
                                <span class="prices" v-show="addressitem.currency == 'EUR'">€</span>
                                <span class="prices" v-show="addressitem.currency == 'CNY'">￥</span>

                                <span class="prices">{{ addressitem.price * num || 0 }}</span>
                                <!--（CNY{{ (addressitem.days *
                                        addressitem.price * num) * 6.9212
                                }}）-->

                            </div>
                            <!-- <div>
                                <p>汇率标准: 1USD = 6.9212CNY</p>
                            </div>
                            -->
                        </div>
                        <!-- 须知 -->
                        <div class="notice">
                            <!-- exchangeRate -->
                            <span>付款公司必须要和注册此账号的公司保持一致，如不符，则无法正常提箱，请知悉</span>
                        </div>
                    </div>

                </div>

            </div>
            <div class="Inquiry_Box_Bottom">
                <div class="Inquiry_Box_Bottom_Small">
                    <!-- 同意 -->
                    <div class="img" @click="agree">
                        <img :src="contract ? check : unchecked" alt="">
                    </div>
                    <!-- 合约 -->
                    <div class="contract">
                        <span>我已阅读</span>
                        <span @click="handleHeYue">《货运合约条款》</span>
                    </div>
                    <div class="Inquiry_Button" @click="handleOrder"><span>立即下单</span></div>
                </div>
            </div>
        </div>
        <div class="contractTerms" v-if="flagHeYue">
            <div class="contractTermsBox">
                <div class="changeClose"> <span @click="flagHeYue = !flagHeYue">X</span> </div>
                <div class="contentS">

                    <div class="contracTitle">货运合约条款
                    </div>
                    <div class="contracContent" v-html="basicagent">
                        {{ basicagent }}
                    </div>
                    <div class="contracBot">
                        <!-- <div class="conLeft">
                        <div class="img" @click="agree">
                            <img :src="contract ? check : unchecked" alt="">
                        </div>
                        <p>
                            <span>我已阅读 </span>
                            <span>《国际货物进（出）口运输代理条款》</span>
                        </p>
                    </div> -->
                        <div class="conRight">
                            <div class="conRight1" @click="flagHeYue = !flagHeYue">上一步</div>
                            <div class="conRight2" @click="handleMe">我已阅读并同意本条款</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Footer_Compontent class="footerS"></Footer_Compontent>
    </div>
</template>

<script>
import axios from 'axios';
import Footer_Compontent from "../../components/Footer_Compontent.vue";
import { postAddOrder, rentBoxList } from '../../tools/ajax';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "Inquiry",
    components: { Footer_Compontent },
    data() {
        return {
            flagHeYue: false,
            contract: false, //同意合约 
            check: require('../../assets/homePage/check-Y.png'), //选中
            unchecked: require('../../assets/homePage/check-N.png'), //未选中
            num: '',  //数量
            telephoneNum: "",//手机号
            addressValue: '', //地址
            value: "",
            orderMessage: '',
            ids: this.$route.query.id,
            boxType: this.$route.boxType,
            addressitem: '',
            choiceValue: null, //提箱堆场
            authInf: "",//公司信息
            basicagent: '',//合约信息


            enterpriseName: '', //下单公司
            enterpriseTel: '',
        }
    },
    created() {
        this.telephoneNum = localStorage.getItem("phone")



        var token = sessionStorage.getItem("token");
        this.authInf = JSON.parse(localStorage.getItem("authInfo"));
        if (this.authInf) {
            this.enterpriseName = this.authInf.enterpriseName;
            this.enterpriseTel = this.authInf.tel;
        }
        console.log(this.authInf);
        axios.get(`${baseUrl}/box/queryById`, {
            params: { id: this.ids },
            headers: {
                'authorization': token
            }
        })
            .then(res => {
                console.log(res.data.result)
                this.orderMessage = res.data.result;
            })
            .catch(err => {
                console.error(err);
            })

    },
    methods: {


        //#region  //限制输入文字

        inputNum2(e) {
            this.telephoneNum = e.target.value;
            this.telephoneNum = this.telephoneNum.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.telephoneNum = this.telephoneNum.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.telephoneNum = this.telephoneNum.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.telephoneNum = this.telephoneNum.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },
        inputNum1(e) {  //限制输入数字
            this.num = e.target.value;
            this.num = this.num.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.num = this.num.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.num = this.num.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.num = this.num.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },
        changeTelpone(e) { //限制输入数字
            console.log("-", this.addressitem.maxBuy);

            if (e.target.value < 1) {
                this.$message({
                    showClose: true,
                    message: '请最少输入一个',
                    type: 'error'
                });
                this.num = 1
                return false
            } else if (e.target.value > this.addressitem.maxBuy) {
                this.$message({
                    showClose: true,
                    message: `最多可选${this.addressitem.maxBuy}个`,
                    type: 'error'
                });
                this.num = this.addressitem.maxBuy
                return false
            }
        },

        //#endregion

        changeOrder(index, e) {
            this.addressitem = this.orderMessage.expList[index]
            this.addressValue = this.addressitem.address;
        },
        handleHeYue() {  //合约条款
            this.flagHeYue = true;
            let reg = new RegExp('<[^<>]+>', 'g');
            axios.get(`${baseUrl}/basic/agent`, {
                headers: { 'Content-Type': 'h5' }
            }).then(res => {
                let cont = res.data.result.content.replace('{{enterpriseName}}', this.enterpriseName);
                this.basicagent = cont;

                //this.basicagent = res.data.result.content;
                // this.basicagent = this.basicagent.replace(reg, "");
                console.log(this.basicagent)
            })
                .catch(err => {
                    console.error(err);
                })
        },
        handleOrder() {  //点击立即下单
            var token = sessionStorage.getItem("token");
            console.log(this.addressValue);
            if (this.addressValue == "") {
                this.$message({
                    showClose: true,
                    message: '请选择提箱堆场',
                    type: 'warning'
                });
                return false
            } else if (this.num == '') {
                this.$message({
                    showClose: true,
                    message: '请选择租赁个数',
                    type: 'warning'
                });
                return false
            } else if (this.telephoneNum == '') {
                this.$message({
                    showClose: true,
                    message: '请输入预留电话',
                    type: 'warning'
                });
                return false
            } else if (this.contract && token) {
                axios.post(`${baseUrl}/boxOrder/addOrder`, {
                    rentId: this.ids,
                    cupboard: this.orderMessage.cupboard,
                    cupboardNum: this.num,
                    boxType: this.boxType,
                    departure: this.orderMessage.departure,
                    destination: this.orderMessage.destination,
                    suitcaseId: this.addressitem.id, //堆场Id
                    suitcaseAddr: this.addressitem.suitcase, //提箱城市
                    mobile: this.telephoneNum, //手机号

                }, {
                    headers: {
                        'authorization': token
                    }
                }).then(res => {
                    console.log(res.data)
                    if (res.data.success == true) {
                        let messages = res.data.message
                        this.$message({
                            showClose: true,
                            message: messages,
                            type: 'success'
                        });
                    } else if (res.data.success == false) {
                        let messages = res.data.message
                        this.$message({
                            showClose: true,
                            message: messages,
                            type: 'error'
                        });
                        this.$router.go(-1)
                    }
                })
                    .catch(err => {
                        console.error(err);
                    })
            } else if (!token) {
                this.$message.error('您还没有登录，请先登录');
            } else {
                this.$message.error('请勾选协议');
            }


        },
        agree() {
            this.contract = !this.contract;
        },
        handleMe() {
            this.flagHeYue = false;
            this.contract = true
        },

        handleChange() {
            console.log("@");
        },
        jia() {

            if (this.num < this.addressitem.maxBuy) {
                this.num++
            }
        },
        jian() {
            if (this.num > 0) {
                this.num--
            }
        },
        choice(value) {
            // console.log("*", value);
            if (this.value) {
                this.placeholder_One = true
            } else {
                this.placeholder_One = false
            }
        }
    },

}
</script>

<style lang="scss" scoped>
.footerS {
    margin-top: 243px;
}

::v-deep .el-icon-arrow-up::before {
    content: "\e78f";
}

// .el-icon-arrow-up:before {
//     content: "\e6e1";
// }
.contractTerms {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(51, 51, 51, 0.1);
    width: 100%;
    // height: 900px;
    z-index: 300;

    .contractTermsBox {
        width: 100%;
        margin: 0px auto;
        background: #FFFFFF;
        height: 100%;
        z-index: 300;

        // overflow: hidden;
        // overflow-y: scroll;
        .contentS {
            width: 1200px;
            margin: 0 auto;
            z-index: 200;
            overflow: hidden;
        }

        .changeClose {
            position: absolute;
            right: 10px;
            // top: 10px;
            width: 100%;
            font-size: 24px;
            cursor: pointer;
            text-align: right;
            background-color: rgb(#000000, .6);
        }

        .contracTitle {
            position: relative;
            font-size: 30px;
            font-weight: bold;
            color: #333333;
            text-align: center;
            margin-top: 28px;


        }

        .contracContent {
            padding: 10px 30px;

            p {
                padding: 10px 30px;
            }

        }



        .contracBot {
            display: flex;
            justify-content: right;
            width: 100%;
            height: 64px;
            margin-top: 33px;
            background: #FFFFFF;
            padding: 0 20px;

            .conLeft {
                display: flex;
                align-items: center;

                p {
                    margin-left: 5px;
                    line-height: 64px;
                }
            }

            .conRight {
                display: flex;

                .conRight1 {
                    width: 150px;
                    height: 40px;
                    border-radius: 30px 30px 30px 30px;
                    border: 1px solid #1890FF;
                    text-align: center;
                    line-height: 40px;
                    font-size: 18px;
                    font-weight: 400;
                    margin-right: 59px;
                    color: #1890FF;
                    cursor: pointer;
                }

                .conRight2 {
                    width: 228px;
                    height: 40px;
                    background: #1890FF;
                    text-align: center;
                    line-height: 40px;
                    font-size: 18px;
                    border-radius: 30px 30px 30px 30px;
                    font-weight: 400;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }
    }
}

.Inquiry {
    width: 100%;
    background: #F4F4F4;
    overflow: hidden;

    .Inquiry_Box {
        width: 1200px;
        margin: 32px auto 0;


        .Inquiry_Box_Top {
            display: flex;
            justify-content: space-around;

            .Inquiry_Box_Top_Left {
                width: 702px;
                height: 509px;
                background: #FFFFFF;

                .Inquiry_Box_Top_Left_Title {
                    width: 702px;
                    height: 40px;
                    background: #1890FF;
                    line-height: 40px;
                    padding-left: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-family: '黑体';
                }

                .Inquiry_Content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    height: calc(466px - 40px);

                    .Inquiry_Content_City {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        position: relative;
                        // width: 596px;
                        padding-bottom: 30px;
                        height: 116px;
                        margin-top: 20px;
                        // border-bottom: 1px solid;

                        .suitcaseCity {
                            display: flex;
                            // flex-direction: column;
                            // justify-content: space-between;
                            padding-left: 104px;
                            // margin-top: 22px;

                            div:nth-child(1) {
                                font-size: 14px;
                                height: 14px;
                                font-family: '黑体';
                                font-weight: 400;
                                color: #333333;
                                line-height: 14px
                            }

                            div:nth-child(2) {
                                width: 481px;
                                font-size: 14px;
                                font-family: '黑体';
                                font-weight: 400;
                                color: #333333;
                                line-height: 14px;
                                margin-left: 21px;
                                padding-bottom: 4px;
                                border-bottom: 1px solid #999999;
                            }
                        }

                        .returnBox {
                            display: flex;
                            padding-left: 104px;
                            // margin-top: 20px;

                            div:nth-child(1) {
                                font-size: 14px;
                                height: 14px;
                                font-family: '黑体';
                                font-weight: 400;
                                color: #333333;
                                line-height: 14px
                            }

                            div:nth-child(2) {
                                width: 481px;
                                font-size: 14px;
                                font-family: '黑体';
                                font-weight: 400;
                                color: #333333;
                                line-height: 14px;
                                margin-left: 21px;
                                padding-bottom: 4px;
                                border-bottom: 1px solid #999999;
                            }
                        }

                        .containerType {
                            display: flex;
                            padding-left: 90px;
                            // margin-top: 20px;

                            // float: right;
                            div:nth-child(1) {
                                font-size: 14px;
                                height: 14px;
                                font-family: '黑体';
                                font-weight: 400;
                                color: #333333;
                                line-height: 14px;
                                // float: right;
                            }

                            // display: flex;
                            div:nth-child(2) {
                                // float: right;
                                width: 481px;
                                font-size: 14px;
                                font-family: '黑体';
                                font-weight: 400;
                                color: #333333;
                                line-height: 14px;
                                margin-left: 21px;
                                padding-bottom: 4px;
                                border-bottom: 1px solid #999999;
                            }
                        }
                    }

                    .Inquiry_Content_City:after {
                        position: absolute;
                        bottom: 0;
                        right: 40px;
                        content: '';
                        width: 596px;
                        height: 1px;
                        background-color: #999999;

                    }

                    .Inquiry_Bc {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        height: 256px;
                        padding-bottom: 20px;
                    }

                    .choice_Box {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 41px;
                        padding-right: 40px;
                        padding-left: 48px;
                        // margin-top: 24px;
                        // overflow: hidden;

                        .choice_Box_Left {
                            font-size: 14px;
                            font-family: '黑体';
                            font-weight: 400;
                            color: #333333;
                            line-height: 40px;
                            margin-left: 34px;
                        }

                        .choice_Box_Right {
                            position: relative;
                            width: 480px;
                            height: 40px;
                            float: right;
                            margin-left: 16px;

                            ::v-deep .el-icon-arrow-up::before {
                                content: "\e78f";
                            }

                            .placeholder {
                                position: absolute;
                                top: -5px;
                                left: 16px;
                                font-size: 10px;
                                font-weight: 400;
                                color: #707070;
                                line-height: 10px;
                            }
                        }

                        ::v-deep .el-form-item__label {
                            margin-left: 16px;
                        }

                        ::v-deep .el-input__inner {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            color: #333333;
                            // border: 1px solid #2B2B2B;
                        }

                        ::v-deep .el-form-item__label {
                            font-size: 14px;
                            font-family: '黑体';
                            font-weight: 400;
                            color: #333333;
                            margin-left: 28px;
                        }

                        .el-select {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            // border: 1px solid #2B2B2B;
                            float: right;
                        }


                    }

                    .suitcaseAddress {
                        height: 40px;
                        margin-left: 111px;

                        .el-select {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            margin-left: 3px;
                        }

                        ::v-deep .el-input__inner {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            color: #333333;
                            // border: 1px solid #2B2B2B;   
                        }
                    }

                    .numberOfRentedBoxes {
                        display: flex;
                        height: 40px;
                        // margin-left: 16px;
                        padding: 0 40px 0 0;
                        justify-content: space-between;

                        .numberOfRentedBoxes_Left {
                            font-weight: 400;
                            color: #333333;
                            line-height: 40px;
                            height: 40px;
                            font-size: 14px;
                            margin-left: 25px;
                            text-align: right;
                        }

                        .numberOfRentedBoxes_Right {
                            position: relative;
                            width: 480px;
                            height: 40px;
                            // margin-left: 16px;
                            border-radius: 4px 4px 4px 4px;
                            font-size: 14px;
                            line-height: 40px;

                            input {
                                width: 100%;
                                height: 100%;
                                border-radius: 4px 4px 4px 4px;
                                padding-left: 16px;
                                color: #707070;
                                color: #333333;
                                border: 1px solid #C0C4CC;
                                cursor: pointer;
                            }

                            input:focus {
                                outline: 1px solid #409EFF;
                            }

                            .controlQuantity {
                                position: absolute;
                                top: 50%;
                                right: 9px;
                                transform: translateY(-50%);

                                i {
                                    display: block;
                                    color: #c0c4cc;
                                }
                            }
                        }

                        .el-select {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                        }

                        ::v-deep .el-input__inner {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            color: #333333;
                            // border: 1px solid #2B2B2B;
                        }
                    }

                    .telephone {
                        position: relative;
                        display: flex;

                        .telephone_Left {
                            font-family: '黑体';
                            font-weight: 400;
                            color: #333333;
                            line-height: 40px;
                            font-size: 14px;
                            margin: 0 12px 0 56px;
                        }

                        .telephone_Right {
                            width: 480px;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            margin-left: 2px;

                            input {
                                width: 100%;
                                height: 100%;
                                border-radius: 4px 4px 4px 4px;
                                font-weight: 400;
                                color: #999999;
                                font-size: 14px;
                                padding-left: 16px;
                                border: 1px solid #C0C4CC;
                                cursor: pointer;
                            }

                            input:focus {
                                outline: 1px solid #409EFF;
                            }
                        }

                        .placeholder_Two {
                            position: absolute;
                            top: -8px;
                            left: 200px;
                            font-weight: 400;
                            color: #707070;
                            font-size: 10px;
                            background-color: white;
                            padding: 0 5px;
                        }

                    }
                }

                .progressBar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 14px;

                    div {
                        position: relative;
                        display: flex;
                        align-items: center;

                        p {
                            width: 16px;
                            height: 16px;
                            background: #1890FF;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 16px;
                            font-size: 12px;
                            color: #FFFFFF;
                        }

                        span {
                            margin-left: 8px;
                        }
                    }

                    div:nth-child(1):after {
                        position: absolute;
                        left: 92px;
                        content: '';
                        width: 80px;
                        height: 1px;
                        background-color: #999999;
                    }

                    div:nth-child(2):after {
                        position: absolute;
                        left: 123px;
                        content: '';
                        width: 80px;
                        height: 1px;
                        background-color: #999999;
                    }

                    div:nth-child(3):after {
                        position: absolute;
                        left: 92px;
                        content: '';
                        width: 80px;
                        height: 1px;
                        background-color: #999999;
                    }
                }
            }

            .Inquiry_Box_Top_Right {
                width: 474px;
                height: 509px;
                background: #FFFFFF;

                .Inquiry_Box_Top_Right_Title {
                    height: 40px;
                    background: #1890FF;
                    line-height: 40px;
                    font-size: 16px;
                    font-family: '黑体';
                    font-weight: 400;
                    color: #FFFFFF;
                    padding-left: 16px;
                }

                .details {
                    box-sizing: border-box;
                    width: 100%;
                    // height:390px;
                    padding: 22px 24px 24px;

                    .quantity {
                        width: 426px;
                        height: 78px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                        border-radius: 4px 4px 4px 4px;

                        .quantity_Title {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 38px;
                            padding: 0 24px;
                        }

                        .quantity_Title:after {
                            position: absolute;
                            left: 0;
                            top: 38px;
                            content: "";
                            width: 426px;
                            height: 0px;
                            opacity: 1;
                            border: 1px solid #999999;
                        }

                        .quantity_Content {
                            display: flex;
                            justify-content: space-between;
                            height: 40px;
                            line-height: 40px;
                            font-weight: 400;
                            color: #999999;
                            line-height: 40px;
                            text-align: center;
                            padding: 0 24px;
                        }
                    }

                    .datePrice {
                        position: relative;
                        display: flex;
                        margin-top: 20px;
                        font-weight: 400;
                        color: #999999;
                        line-height: 24px;
                        padding-bottom: 15px;
                        font-size: 14px;

                        div:nth-child(2) {
                            margin-left: 129px;
                            color: #333333;
                            font-size: 16px;
                        }
                    }

                    .datePrice:after {
                        position: absolute;
                        bottom: 0;
                        content: '';
                        width: 426px;
                        height: 0px;
                        opacity: 1;
                        border: 1px solid #999999;
                    }

                    .address {
                        display: flex;
                        margin-top: 15px;
                        font-weight: 400;
                        color: #999999;
                        line-height: 24px;
                        font-size: 14px;

                        div:nth-child(2) {
                            font-size: 16px;
                            margin-left: 129px;
                            color: #333333;
                        }
                    }

                    .totalPrice {
                        margin-top: 20px;

                        div:nth-child(1) {
                            font-size: 18px;
                            font-weight: bold;
                            color: #333333;
                            line-height: 24px;

                            span:nth-child(2) {

                                color: #D4252C;
                                margin-left: 8px;
                            }

                            .prices {
                                color: #D4252C;
                                margin-left: 8px;
                            }
                        }

                        div:nth-child(2) {
                            // width: 182px;
                            height: 14px;
                            font-size: 14px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                            line-height: 24px;
                        }
                    }

                    .notice {
                        margin-top: 20px;
                        font-size: 14px;
                        color: #999999;
                    }
                }


            }


        }

        .Inquiry_Box_Bottom {
            width: 1200px;
            height: 64px;
            margin: 16px auto 0;
            background: #FFFFFF;
            overflow: hidden;

            .Inquiry_Box_Bottom_Small {
                float: right;
                display: flex;
                align-items: center;
                margin-right: 64px;
                line-height: 64px;

                .img {
                    position: relative;
                    width: 15px;
                    height: 15px;
                    // border: 2px solid #D4252C;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .contract {
                    margin-left: 6px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 55px;
                    font-size: 18px;

                    span:nth-child(2) {
                        cursor: pointer;
                        color: #D4252C;
                    }
                }

                .Inquiry_Button {
                    cursor: pointer;
                    width: 150px;
                    height: 40px;
                    margin: 12px 0;
                    background: #1890FF;
                    box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
                    border-radius: 30px 30px 30px 30px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 18px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>